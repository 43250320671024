body {
  background-color: #f1f1f1;
  color: #f1f1f1;
  font-family: Arial, Helvetica, sans-serif;
  height: 100%;
}

html {
  height: 100%;
}

.wrapper{
  overflow-x: hidden;
}