.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px;
    background-color: #2b2b2b;
    color: #f1f1f1;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    max-width: 600px;
    width: 100%;
  }
  
  .contact-form label {
    font-size: 1.2em;
    margin-bottom: 10px;
  }
  
  .contact-form input,
  .contact-form textarea {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1em;
    margin-bottom: 20px;
    background-color: #2b2b2b;
    color: #f1f1f1;
  }
  
  .contact-form textarea {
    height: 150px;
  }
  
  .contact-form button {
    background-color: #f1f1f1;
    color: #333;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 1em;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .contact-form button:hover {
    background-color: #e6e6e6;
  }
  
  .contact-info {
    max-width: 600px;
    width: 100%;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .contact-info div {
    flex-basis: 50%;
    text-align: center;
  }
  
  .contact-info i {
    font-size: 2em;
    margin-bottom: 10px;
    display: block;
  }
  
  .contact-info p {
    font-size: 1em;
    margin-bottom: 0;
    color: #ccc;
  }
  
  .contact-info a {
    color: #f1f1f1;
    text-decoration: none;
    transition: color 0.3s ease;
  }
  
  .contact-info a:hover {
    color: #e6e6e6;
  }